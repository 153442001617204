import {IS_SSR, IS_TEST, TREE} from './globals'

// Importing tizra/log would be circular, so make a simple logger of our own.
const log = new Proxy(console, {
  get(target, prop, receiver) {
    if (
      prop !== 'log' &&
      prop !== 'error' &&
      prop !== 'debug' &&
      prop !== 'warn'
    )
      return Reflect.get(target, prop, receiver)
    if (IS_TEST || IS_SSR) return () => {}
    return (s: any, ...rest: any[]) =>
      console[prop](`${TREE}tizra/storage: ${s}`, ...rest)
  },
})

const sk = 'localStorage'
const storage = (typeof window !== 'undefined' && window[sk]) || undefined
let errored = false

export function get(k: string): string | undefined {
  if (storage) {
    try {
      const v = storage.getItem(k) ?? undefined
      log.debug?.(`${sk}.getItem`, {k, v})
      return v
    } catch (e) {
      if (!errored) log.error(`${sk}.getItem failed`, {k}, e)
      errored = true
    }
  }
}

export function put<V extends string>(k: string, v: V): V {
  if (storage) {
    try {
      log.debug?.(`${sk}.setItem`, {k, v})
      storage.setItem(k, v)
    } catch (e) {
      if (!errored) log.error(`${sk}.setItem failed`, {k}, e)
      errored = true
    }
  }
  return v
}

export function remove(k: string): void {
  if (storage) {
    try {
      log.debug?.(`${sk}.removeItem`, {k})
      return storage.removeItem(k)
    } catch (e) {
      if (!errored) log.error(`${sk}.removeItem failed`, {k}, e)
      errored = true
    }
  }
}
