import * as R from 'rambdax'
import {isPlainObject, notNullish} from './types'
import {
  CamelCase,
  CamelCasedPropertiesDeep,
  KebabCase,
  KebabCasedPropertiesDeep,
} from 'type-fest'

type Customizer = (
  k: string,
  v: unknown,
  recamel: (x: unknown) => unknown,
) => string | [string | null, unknown]

/**
 * Convert JSON-able structure from one case to another. Works on arrays and
 * objects recursively. Does not mutate the original object.
 */
function ize(x: unknown, customize: Customizer): unknown {
  const recamel = (xx: unknown) => ize(xx, customize)
  return (
    Array.isArray(x) ? x.map(recamel)
    : isPlainObject(x) ?
      Object.fromEntries(
        Object.entries(x)
          .map(([k, v]) => {
            const cust = customize(k, v, recamel)
            return Array.isArray(cust) ? cust : [cust, recamel(v)]
          })
          .filter(([k]) => notNullish(k)),
      )
    : x
  )
}

/**
 * Convert JSON-able structure from kebab-case to camelCase. Works on arrays and
 * objects recursively. Does not mutate the original object.
 */
export const camelize = <T>(x: T) =>
  ize(x, camelCase) as CamelCasedPropertiesDeep<T>

/**
 * Convert JSON-able structure from camelCase to kebab-case. Works on arrays and
 * objects recursively. Does not mutate the original object.
 */
export const kebabize = <T>(x: T) =>
  ize(x, kebabCase) as KebabCasedPropertiesDeep<T>

const TIZRA_CAMEL_STOP_NAMES = [
  'props',
  'parsed-props',
  'parsed_props',
  'proc-props',
  'proc_props',
] as const

type TizraCamelStopName = (typeof TIZRA_CAMEL_STOP_NAMES)[number]

const TIZRA_CAMEL_MAP_NAMES = [
  'prop-defs-including-subtypes',
  'tag-definitions-including-subtypes',
  'info-required',
  'errors',
  'first-steps',
  'next-steps',
] as const

type TizraCamelMapName = (typeof TIZRA_CAMEL_MAP_NAMES)[number]

const TIZRA_CAMEL_RENAMES = {
  'parsed-props': 'props',
  parsed_props: 'props',
  props: null, // drop
  'tag-definitions-including-subtypes': null, // drop
} as const

type TizraCamelRenames = typeof TIZRA_CAMEL_RENAMES

type TizraCamelCasedPropertiesDeep<Value> =
  Value extends (
    | Function // eslint-disable-line @typescript-eslint/no-unsafe-function-type
    | Date
    | RegExp
  ) ?
    Value
  : Value extends Array<infer U> ? Array<TizraCamelCasedPropertiesDeep<U>>
  : Value extends Set<infer U> ? Set<TizraCamelCasedPropertiesDeep<U>>
  : {
      [K in keyof Value as K extends keyof TizraCamelRenames ?
        TizraCamelRenames[K] extends string ?
          TizraCamelRenames[K]
        : never
      : K extends TizraCamelMapName ? K
      : CamelCase<K>]: K extends TizraCamelStopName ? Value[K]
      : TizraCamelCasedPropertiesDeep<Value[K]>
    }

/**
 * Camel-casing with special support for Tizra API responses.
 */
export function tizraCamelize<T>(x: T) {
  // prettier-ignore
  return ize(x, (k, v, recurse) => [
    // Rename parsed-props to props, and props to rawProps, since in code the
    // parsed props are the interesting ones.
    (k in TIZRA_CAMEL_RENAMES) ?
      TIZRA_CAMEL_RENAMES[k as keyof typeof TIZRA_CAMEL_RENAMES] : camelCase(k),

    // Do not recurse into props, because the keys are the property names as
    // specified in the meta-type. Keep them as-is so that they match the spec
    // returned by the search-types API.
    TIZRA_CAMEL_STOP_NAMES.includes(k as TizraCamelStopName) ? v :

    // Skip through prop-defs keys, to avoid mangling them, but do the stuff
    // inside.
    TIZRA_CAMEL_MAP_NAMES.includes(k as TizraCamelMapName) && isPlainObject(v) ? R.map(recurse, v) :

    // Otherwise, carry on!
    recurse(v),
  ]) as TizraCamelCasedPropertiesDeep<T>
}

/**
 * Kebab-casing with special support for Tizra API requests.
 * (Not very special yet.)
 */
export function tizraKebabize<T>(obj: T) {
  return kebabize(obj)
}

/**
 * Convert a string key from kebab-case or snake_case to camelCase. Passes
 * through non-string keys as-is.
 */
export function camelCase<T>(k: T): T extends string ? CamelCase<T> : T {
  // @ts-expect-error
  return typeof k === 'string' ?
      k.replace(/[-_\s]+(\w|$)/g, (_, letter) => letter.toUpperCase())
    : k
}

/**
 * Convert a string key from camelCase to kebab-case. Passes through non-string
 * keys as-is.
 */
export function kebabCase<T>(k: T): T extends string ? KebabCase<T> : T {
  // @ts-expect-error
  return typeof k === 'string' ?
      k.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase()
    : k
}
